import { SelectedDiagnosisSlot } from '@/components/templates/doctor/detail/useDoctorDetailViewModel';
import { UserTelemedicineEligibleGroupType } from '@/models/common';
import { DiagnosisCategoryType } from '@/models/diagnosis/index';
import { DepreactedCategoryType } from '@/services/doctor/DiagnosisCategoryService';
import { MedicalCategoryType } from '@/types/bff/gql-schema';
import { Doctor, HospitalBrand } from '@/types/doctor';

export const NATIVE_POST_MESSAGE_TYPE = {
  // 뒤로가기
  GO_BACK: 'GO_BACK',
  // Event Logging
  LOGGER: 'LOGGER',
  // 로그인 화면 이동
  MOVE_TO_LOGIN: 'MOVE_TO_LOGIN',
  // 특정 유저앱 페이지 이동
  MOVE_TO_PAGE: 'MOVE_TO_PAGE',
  // 특정 유저앱 탭/페이지 이동 (jumpto)
  MOVE_TO_TAB_HOME: 'MOVE_TO_TAB_HOME',
  // 특정 유저앱 페이지 이동 (replace)
  REPLACE_TO_PAGE: 'REPLACE_TO_PAGE',
  // 외부 브라우저 열기
  OPEN_EXTERNAL_BROWSER: 'OPEN_EXTERNAL_BROWSER',
  // 전화걸기.
  CALL: 'CALL',
  // 앱 redux neverSeeAgainState
  SET_NEVER_SEE_AGAIN: 'SET_NEVER_SEE_AGAIN',
  // 앱 버전 요청
  GET_APP_VERSION: 'GET_APP_VERSION',
  // 뒤로가기 제어 상태
  SET_PREVENT_BACK: 'SET_PREVENT_BACK',
  // 카카오 페이 등록
  REGISTER_KAKAO_PAY: 'REGISTER_KAKAO_PAY',
  // 첫번째 페이지 제외 페이지 리셋
  RESET_OVER_FIRST_PAGE: 'RESET_OVER_FIRST_PAGE',
  // 채널톡 열기
  OPEN_CHANNEL_TALK: 'OPEN_CHANNEL_TALK',
  // 앱 리뷰 열기
  OPEN_APP_REVIEW: 'OPEN_APP_REVIEW',
  // 카드 선택 BottomSheet 호출
  SELECT_CARD: 'SELECT_CARD',
  // 결제 수단 관리 페이지 이동
  MOVE_TO_PAYMENT_PAGE: 'MOVE_TO_PAYMENT_PAGE',
  // 진료 대상군 체크 후 의사 상세페이지 이동
  MOVE_TO_DOCTOR_DETAIL_PAGE_AFTER_DIAGNOSIS_TARGET_CHECK:
    'MOVE_TO_DOCTOR_DETAIL_PAGE_AFTER_DIAGNOSIS_TARGET_CHECK',
  RESET_NAVIGATION_TO_SELECTED_SCREEN: 'RESET_NAVIGATION_TO_SELECTED_SCREEN',
  // 본인인증 페이지 이동
  MOVE_CERTIFICATION_SCREEN: 'MOVE_CERTIFICATION_SCREEN',
  // 앱에서 커스텀 데이터독 액션 로깅
  ADD_ACTION_FOR_RUM: 'ADD_ACTION_FOR_RUM',
  STACK_POP_TO_POP: 'STACK_POP_TO_POP',
  OPEN_MISSION_TARGET_LINK: 'OPEN_MISSION_TARGET_LINK',
  // 광고 보기,
  VIEW_ADVERTISEMENT: 'VIEW_ADVERTISEMENT',
  MOVE_TO_DOCTOR_LIST_WITH_TARGET_CHECK:
    'MOVE_TO_DOCTOR_LIST_WITH_TARGET_CHECK',
  MOVE_TO_CATEGORY_SELECT: 'MOVE_TO_CATEGORY_SELECT',
  GO_BACK_AND_SET_PARAMS: 'GO_BACK_AND_SET_PARAMS',
  SET_PREVENT_BACK_EVENT: 'SET_PREVENT_BACK_EVENT',
  DOCTOR_DETAIL_OPEN_MAP: 'DOCTOR_DETAIL_OPEN_MAP',
  DOCTOR_DETAIL_CALL_HOSPITAL: 'DOCTOR_DETAIL_CALL_HOSPITAL',
  DOCTOR_DETAIL_REQUEST_TREATMENT: 'DOCTOR_DETAIL_REQUEST_TREATMENT',
  NAVIGATE_TO_PHARMACY_FIND_WITH_CURRENT_LOCATION:
    'NAVIGATE_TO_PHARMACY_FIND_WITH_CURRENT_LOCATION',
  COMPLETE_TREATMENT_REQUEST: 'COMPLETE_TREATMENT_REQUEST',
  DOCTOR_DETAIL_REQUEST_TREATMENT_WITH_TARGET_ID:
    'DOCTOR_DETAIL_REQUEST_TREATMENT_WITH_TARGET_ID',
  REGISTER_PAYMENT_KAKAO_PAY: 'REGISTER_PAYMENT_KAKAO_PAY',
  REQUEST_VIDEO_CALL_PERMISSION: 'REQUEST_VIDEO_CALL_PERMISSION',
  POP_WITH_REPLACE_AT_PRESCRIPTION_SEND_FAIL_SCREEN:
    'POP_WITH_REPLACE_AT_PRESCRIPTION_SEND_FAIL_SCREEN',
  INVALIDATE_DIAGNOSIS_DETAIL_CACHE_AT_PRESCRIPTION_RE_SEND_SCREEN:
    'INVALIDATE_DIAGNOSIS_DETAIL_CACHE_AT_PRESCRIPTION_RE_SEND_SCREEN',
  NAVIGATE_TO_PHARMACY_PICK_UP_WITH_LOCATION:
    'NAVIGATE_TO_PHARMACY_PICK_UP_WITH_LOCATION',
  DISMISS_KEYBOARD: 'DISMISS_KEYBOARD',
  ROUTE_RESET_TO_DIAGNOSIS_DETAIL_AT_PRESCRIPTION_SEND_FAX_INPUT_SCREEN:
    'ROUTE_RESET_TO_DIAGNOSIS_DETAIL_AT_PRESCRIPTION_SEND_FAX_INPUT_SCREEN',
  NAVIGATE_TO_PRESCRIPTION_SEND_SUCCESS_WEBVIEW_SCREEN_AFTER_ROUTE_RESET:
    'NAVIGATE_TO_PRESCRIPTION_SEND_SUCCESS_WEBVIEW_SCREEN_AFTER_ROUTE_RESET',
  LOGGING_AT_PRESCRIPTION_SEND_FAX_CHECK_WEBVIEW_SCREEN:
    'LOGGING_AT_PRESCRIPTION_SEND_FAX_CHECK_WEBVIEW_SCREEN',
  CALL_TO_PHARMACY: 'CALL_TO_PHARMACY', // 기존 call 사용법이 컨벤션과 맞지 않아 임시로 추가
  COMPLETE_DIAGNOSIS_REQUEST: 'COMPLETE_DIAGNOSIS_REQUEST',
  MOVE_DIAGNOSIS_LIST_AFTER_REQUEST_COMPLETE:
    'MOVE_DIAGNOSIS_LIST_AFTER_REQUEST_COMPLETE',
};

export type NATIVE_POST_MESSAGE_TYPE =
  typeof NATIVE_POST_MESSAGE_TYPE[keyof typeof NATIVE_POST_MESSAGE_TYPE];

interface MoveToPageParams {
  screenName: string;
  params?: {
    [key: string]: any;
  };
}

interface GoBackAndSetParamsParams {
  [key: string]: string | number | boolean | null | string[];
}

interface MoveToDoctorListParams {
  typeId?: number;
  type?: string;
}

interface MoveToDoctorDetailPageParams {
  doctorId?: string;
  type?: DepreactedCategoryType;
  categoryId?: number;
}

interface AirBridgeEventOption {
  action?: string;
  label?: string;
  value?: number;
  attributes?: {
    [key: string]: string;
  };
  semantics?: {
    [key: string]: unknown;
  };
}

interface DatadogCustomParams {
  actionName: string;
  params?: Record<string, unknown>;
}

interface LoggerParams {
  logger: {
    eventName: string;
    pageName: string;
    payload?: {
      [key: string]: string | number;
    };
    options?: AirBridgeEventOption;
  };
}

interface openExternalBrowserParams {
  url: string;
}

interface CallParams {
  tel: string;
}

interface MoveToQuestionDetailParams {
  questionId: number;
}

interface MoveToTabHomeParams {
  tabName: MoveToTabHomeTabs;
  screenName: string;
  params?: {
    [key: string]: string | number | boolean | any;
  };
}

interface MissionAdvertisementParams {
  missionId: string;
}

type MoveToTabHomeTabs =
  | 'HomeTab'
  | 'SearchHospitalTab'
  | 'QuickAnswerTab'
  | 'HealthTab'
  | 'MyPageTab';

interface GlobalToastParams {
  isVisible: boolean;
  message?: string;
  visibleTime?: number;
  component?: Element;
  bottom?: number;
  timeoutId?: number;
  simpleTextBox?: boolean;
}

interface TagFilterParams {
  questionTagId: number;
}

interface SetNeverSeeAgainParams {
  state: string;
}

interface LinkShareParams {
  link: string;
  title?: string;
  message?: string;
}

interface SetPreventBackParams {
  preventBack: boolean;
}

interface ResetNavigationParams {
  index: number;
  screenName: string;
  params?: {
    [key: string]: string | number | boolean;
  };
}

interface RegisterKakaoPayParams {
  pgToken: string;
}

interface SearchRequestInfoParams {
  keyword: string;
}

interface SearchRequestDetailInfoParams {
  keyword: string;
  categoryId: number;
}

interface CounselingInProgressParams {
  counselingId: string;
}

// mobile web + webview
interface DebugParams {
  message: string;
}

interface OpenLinkParams {
  link: string;
  missionId: string;
}

interface MoveToDoctorListWithTargetCheckParams {
  type: MedicalCategoryType;
  id: string;
}

interface DoctorDetailCallHospitalParams {
  tel: string;
}

interface DoctorDetailOpenMapParams {
  hospitalName: string;
  latitude: number;
  longitude: number;
}

interface TreatmentRequestParams {
  doctor: Doctor & {
    brand?: HospitalBrand;
    selectedDiagnosisSlot?: SelectedDiagnosisSlot;
  };
}

interface CompleteTreatmentRequestParams {
  treatmentMedicineId?: string | null;
  diagnosisCategoryType: DiagnosisCategoryType;
  diagnosisCategoryTypeId: string;
  isQuickDiagnosis: boolean;
}

interface MoveCertificationScreenParams {
  params?: {
    base?: string;
  };
}

interface InvalidateDiagnosisDetailCacheAtPrescriptionReSendScreenParams {
  params: {
    diagnosisId?: string | string[];
  };
}

interface NavigateToPharmacyPickUpWithLocationParams {
  params: {
    diagnosisId?: string | string[];
  };
}

interface GetLocationPositionAppMessage {
  latitude: number;
  longitude: number;
}

interface CallToPharmacyParams {
  params: {
    tel: string | string[];
  };
}

interface TreatmentRequestCompleteParams {
  treatmentMedicineId: string;
}

export const isMobile = (userAgent: string) => {
  return /Android|iPhone|iPad|iPod|BlackBerry|Windows Phone|RNWebView/i.test(
    userAgent,
  );
};

// webview
export const isWebViewByUserAgent = (userAgent: string) => {
  return /RNWebView/i.test(userAgent);
};

// mobile web
export const isMobileWebByUserAgent = (userAgent: string) => {
  return (
    /Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent) &&
    !/RNWebView/i.test(userAgent)
  );
};

export const isWebView = () => {
  return typeof window !== 'undefined' && !!window?.ReactNativeWebView;
};

export const isAndroid = () => {
  const userAgent = navigator?.userAgent;
  return /android/i.test(userAgent);
};

export const isAndroidByUserAgent = (userAgent: string) => {
  return /android/i.test(userAgent);
};

export const getUserAgent = () => {
  return navigator?.userAgent;
};

export const historyBack = () => {
  if (isWebView()) {
    sendPostMessageToNative(NATIVE_POST_MESSAGE_TYPE.GO_BACK);
  } else {
    window.history.back();
  }
};

export const sendPostMessageToNative = (
  type: NATIVE_POST_MESSAGE_TYPE,
  payload?:
    | LoggerParams
    | openExternalBrowserParams
    | MoveToDoctorListParams
    | MoveToDoctorDetailPageParams
    | CallParams
    | MoveToQuestionDetailParams
    | MoveToPageParams
    | MoveToTabHomeParams
    | TagFilterParams
    | GlobalToastParams
    | SetNeverSeeAgainParams
    | LinkShareParams
    | SetPreventBackParams
    | ResetNavigationParams
    | RegisterKakaoPayParams
    | SearchRequestInfoParams
    | SearchRequestDetailInfoParams
    | DebugParams
    | CounselingInProgressParams
    | DatadogCustomParams
    | OpenLinkParams
    | MissionAdvertisementParams
    | MoveToDoctorListWithTargetCheckParams
    | GoBackAndSetParamsParams
    | DoctorDetailCallHospitalParams
    | DoctorDetailOpenMapParams
    | TreatmentRequestParams
    | CompleteTreatmentRequestParams
    | MoveCertificationScreenParams
    | InvalidateDiagnosisDetailCacheAtPrescriptionReSendScreenParams
    | NavigateToPharmacyPickUpWithLocationParams
    | CallToPharmacyParams
    | TreatmentRequestCompleteParams,
) => {
  try {
    if (!isWebView()) {
      throw new Error('Native Plugin을 확인할 수 없습니다.');
    } else {
      console.log('postMessage: ', { type, payload });
      window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }));
    }
  } catch (error) {
    const e = error as Error;
    console.log('postMessage Error: ', e.message);
  }
};

enum NATIVE_INTERACTION_MESSAGE_TYPE {
  REQUEST_CAMERA_PERMISSION = 'REQUEST_CAMERA_PERMISSION',
  CHECK_LOCATION_PERMISSION = 'CHECK_LOCATION_PERMISSION',
  GET_LOCATION_POSITION = 'GET_LOCATION_POSITION',
  REQUEST_LOCATION_PERMISSION = 'REQUEST_LOCATION_PERMISSION',
  VIEW_ADVERTISEMENT = 'VIEW_ADVERTISEMENT',
  REQUEST_TELEMEDICINE_ELIGIBLE_GROUP_TYPE = 'REQUEST_TELEMEDICINE_ELIGIBLE_GROUP_TYPE',
}

interface WebViewResponse<T> {
  type: NATIVE_INTERACTION_MESSAGE_TYPE;
  data: T;
}

const webViewCustomRequest = <T,>(
  callback: () => void,
  type: NATIVE_INTERACTION_MESSAGE_TYPE,
  timeout = 10000,
) => {
  if (!isWebView()) {
    throw new Error('Native Plugin을 확인할 수 없습니다.');
  } else {
    return new Promise<T>((resolve, reject) => {
      const timer = setTimeout(
        () =>
          reject(
            new Error('앱으로 보낸 요청의 응답 가능 시간을 벗어났습니다.'),
          ),
        timeout,
      );

      const handler = (event: MessageEvent) => {
        clearTimeout(timer);

        const { data, type: responseType }: WebViewResponse<T> = JSON.parse(
          event.data,
        );

        if (responseType === type) {
          resolve(data);
          document.removeEventListener('message', handler as EventListener);
          window.removeEventListener('message', handler);
        }
      };
      document.addEventListener('message', handler as EventListener);
      window.addEventListener('message', handler);
      callback();
    });
  }
};

const requestAppMessage = async <T,>(
  type: NATIVE_INTERACTION_MESSAGE_TYPE,
  timeout?: number,
) => {
  return webViewCustomRequest<T>(
    () => window.ReactNativeWebView.postMessage(JSON.stringify({ type })),
    type,
    timeout,
  );
};

const requestAppMessageWithPayload = async <T, K>(
  type: NATIVE_INTERACTION_MESSAGE_TYPE,
  payload: K,
  timeout?: number,
) => {
  return webViewCustomRequest<T>(
    () =>
      window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload })),
    type,
    timeout,
  );
};

export const nativeEvents = {
  requestCameraPermission: (): Promise<boolean> => {
    return requestAppMessage<boolean>(
      NATIVE_INTERACTION_MESSAGE_TYPE.REQUEST_CAMERA_PERMISSION,
    );
  },
  checkLocationPermission: (): Promise<boolean> => {
    return requestAppMessage<boolean>(
      NATIVE_INTERACTION_MESSAGE_TYPE.CHECK_LOCATION_PERMISSION,
    );
  },
  getLocationPosition: (): Promise<GetLocationPositionAppMessage> => {
    return requestAppMessage<GetLocationPositionAppMessage>(
      NATIVE_INTERACTION_MESSAGE_TYPE.GET_LOCATION_POSITION,
    );
  },
  requestLocationPermission: (): Promise<GetLocationPositionAppMessage> => {
    return requestAppMessage<GetLocationPositionAppMessage>(
      NATIVE_INTERACTION_MESSAGE_TYPE.REQUEST_LOCATION_PERMISSION,
    );
  },
  requestTelemedicineEligibleGroupType:
    (): Promise<UserTelemedicineEligibleGroupType> => {
      return requestAppMessage<UserTelemedicineEligibleGroupType>(
        NATIVE_INTERACTION_MESSAGE_TYPE.REQUEST_TELEMEDICINE_ELIGIBLE_GROUP_TYPE,
        120000,
      );
    },
  requestViewRewardedAd: (missionId: string): Promise<boolean> => {
    return requestAppMessageWithPayload<boolean, { missionId: string }>(
      NATIVE_INTERACTION_MESSAGE_TYPE.VIEW_ADVERTISEMENT,
      { missionId },
      120 * 1000,
    );
  },
};
