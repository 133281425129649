import { withOpacity } from '@/utils/opacity';

/**
 * string이 아닌 number로 사용되어야 할듯..
 */
const size = {
  mainContentsArea: '768px',
  tablet: '768px',
  appHeader: {
    height: '60px',
  },
  footerButton: {
    height: '60px',
  },
  stickyButton: {
    height: 108,
  },
  mobileWebHeader: {
    height: 60,
  },
  headerSearchBar: {
    height: 60,
  },
  realtimeAnswerStatusBar: {
    height: 57,
  },
  searchFilter: {
    height: 60,
  },
} as const;

const color = {
  PRIMARY100: '#FFF4E6',
  PRIMARY200: '#FFE8CC',
  PRIMARY300: '#FFD8A8',
  PRIMARY400: '#FFC078',
  PRIMARY500: '#FF8D00',
  PRIMARY600: '#FD7E14',
  PRIMARY700: '#F76707',
  PRIMARY800: '#E8590C',
  PRIMARY900: '#D9480F',

  BLUE100: '#E7F5FF',
  BLUE200: '#D0EBFF',
  BLUE300: '#A5D8FF',
  BLUE400: '#74C0FC',

  BLUE500: '#339AF0',
  BLUE600: '#228BE6',

  BLUE700: '#1C7ED6',
  BLUE800: '#1971C2',
  BLUE900: '#1864AB',

  GREEN100: '#EBFBEE',
  GREEN200: '#D3F9D8',
  GREEN300: '#B2F2BB',
  GREEN400: '#8CE99A',
  GREEN500: '#51CF66',
  GREEN600: '#40C057',
  GREEN700: '#37B24D',
  GREEN800: '#2F9E44',
  GREEN900: '#2B8A3E',

  YELLOW100: '#FFF9DB',
  YELLOW200: '#FFF3BF',
  YELLOW300: '#FFEC99',
  YELLOW400: '#FFE066',
  YELLOW500: '#FCC419',
  YELLOW600: '#FAB005',
  YELLOW700: '#F59F00',
  YELLOW800: '#F08C00',
  YELLOW900: '#E67700',

  RED100: '#FFF5F5',
  RED200: '#FFE3E3',
  RED300: '#FFC9C9',
  RED400: '#FFA8A8',
  RED500: '#FF6B6B',
  RED600: '#FA5252',
  RED700: '#F03E3E',
  RED800: '#E03131',
  RED900: '#C92A2A',

  WHITE: '#FFFFFF',

  GRAY10: '#FBFCFD',
  GRAY20: '#F7F9FA',
  GRAY30: '#F5F6F7',
  GRAY40: '#F1F3F6',
  GRAY50: '#EDEFF0',
  GRAY100: '#DFE1E2',
  GRAY200: '#C6CACE',
  GRAY300: '#A9AEB1',
  GRAY400: '#8D9297',
  GRAY500: '#71767A',
  GRAY600: '#565C65',
  GRAY700: '#3D4551',
  GRAY800: '#2D2E2F',
  GRAY900: '#1C1D1F',
  BLACK: '#000000',
  DIMMED80: withOpacity('#000000', '80%'),
  DIMMED40: withOpacity('#000000', '40%'),
  TRANSPARENT: 'transparent',

  KAKAO: '#ffe337',
} as const;

const defaultTypography = `
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
` as const;

const typography = {
  UI64B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 64px;
      line-height: 96px;
  `,
  UI56B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 56px;
      line-height: 68px;
  `,
  // UI Text (B/SB/M/R)
  UI48B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
    `,
  UI48SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
    `,
  UI40B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
    `,
  UI40SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 40px;
      line-height: 52px;
    `,
  UI36B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
    `,
  UI36SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
    `,
  UI32B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
    `,
  UI32SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
    `,
  UI28B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
    `,
  UI28SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
    `,
  UI26B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
    `,
  UI26SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 26px;
      line-height: 36px;
    `,
  UI24B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    `,
  UI24SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    `,
  UI24M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    `,
  UI22B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
    `,
  UI22SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    `,
  UI20B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    `,
  UI20SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    `,
  UI20M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    `,
  UI20R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    `,
  UI19B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 19px;
      line-height: 26px;
    `,
  UI19SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 19px;
      line-height: 26px;
    `,
  UI19M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 19px;
      line-height: 26px;
    `,
  UI19R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 19px;
      line-height: 26px;
    `,
  UI18B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    `,
  UI18SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    `,
  UI18M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    `,
  UI18R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    `,
  UI17B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 17px;
      line-height: 24px;
    `,
  UI17SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
    `,
  UI17M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
    `,
  UI17R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
    `,
  UI16B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    `,
  UI16SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    `,
  UI16M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    `,
  UI16R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    `,
  UI15B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
    `,
  UI15SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    `,
  UI15M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    `,
  UI15R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    `,
  UI14B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    `,
  UI14SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    `,
  UI14M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    `,
  UI14R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    `,
  UI13B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
    `,
  UI13SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    `,
  UI13M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    `,
  UI13R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    `,
  UI12B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    `,
  UI12SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    `,
  UI12M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    `,
  UI12R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    `,
  UI11SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    `,
  // UI Sub (M/R)
  UI11M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
    `,
  UI11R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
    `,
  UI10SB: `
    ${defaultTypography}
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  `,
  UI10M: `
    ${defaultTypography}
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  `,
  UI10R: `
    ${defaultTypography}
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  `,
  ARTICLE18M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
    `,
  ARTICLE18R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
    `,
  ARTICLE16M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
    `,
  ARTICLE16R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    `,
} as const;

const fontWeight = {
  BOLD: 700,
  SEMI_BOLD: 600,
  MEDIUM: 500,
  REGULAR: 400,
} as const;

const shadow = {
  BOX_SHADOW_LARGE:
    '0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.04)',
  BUTTON_SHADOW_SMALL: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  DROP_DOWN_SHADOW_SMALL: '0px 4px 8px rgba(0, 0, 0, 0.08)',
} as const;

const breakPoint = {
  SMALL_MOBILE: '400px',
};

const theme = {
  size,
  color,
  typography,
  fontWeight,
  shadow,
  breakPoint,
};

export default theme;
